<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="black"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center"
          ><span style="color: #fff">
            {{ msg }}
          </span></v-flex
        >
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center pt-5 pa-5>
      <v-flex xs12 sm4 md5>
        <v-layout wrap>
          <v-flex xs12 text-left>
            <span style="color: #000; font-family: poppinsbold; font-size: 25px"
              >Course List</span
            >
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm2 md3 pa-2>
        <v-select
          dense
          :items="categoryArrayFilter"
          v-model="categoryFilter"
          label="Category"
          outlined
          clearable
          item-text="name"
          item-value="_id"
        ></v-select>
      </v-flex>
      <v-flex xs12 sm2 md3 pa-2>
        <v-select
          dense
          :items="subCategoryArrayFilter"
          v-model="subCategoryFilter"
          label="SubCategory"
          outlined
          clearable
          item-text="name"
          item-value="_id"
        ></v-select>
      </v-flex>
      <v-flex xs12 sm4 md1 pt-2 text-right>
        <v-dialog
          :retain-focus="false"
          v-model="dialog"
          persistent
          max-width="800px"
          :key="dialog"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined color="green" v-bind="attrs" v-on="on"> ADD </v-btn>
          </template>
          <v-card>
            <v-form>
              <v-card-title>
                <span class="headline">Add Details</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-select
                        dense
                        outlined
                        v-model="category"
                        class="mt-5"
                        label="Category"
                        :items="categoryArray"
                        item-text="name"
                        item-value="_id"
                        required
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select
                        dense
                        outlined
                        v-model="subCategory"
                        class="mt-5"
                        label="Sub Category"
                        :items="subCategoryArray"
                        item-text="name"
                        item-value="_id"
                        required
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        outlined
                        dense
                        v-model="courceName"
                        label="Course Name"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        outlined
                        dense
                        v-model="studyMaterialLink"
                        label="Course Material Link"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        outlined
                        dense
                        v-model="amount"
                        label="Amount"
                        required
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        outlined
                        dense
                        v-model="offerPrice"
                        label="Offer Price"
                        required
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-select
                        dense
                        outlined
                        v-model="courseIds"
                        multiple
                        class="mt-0"
                        label="Courses included"
                        :items="coursesNewArray"
                        item-text="courseName"
                        item-value="_id"
                        required
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12">
                      <span style="font-family: poppinssemibold; color: #000"
                        >Please Upload PDF</span
                      >
                      <v-layout justify-center wrap pb-2 v-if="file">
                        <v-flex xs12 text-center>
                          <span
                            style="font-family: poppinsregular; color: #8f8f8f"
                            >{{ file.name }}</span
                          >
                        </v-flex>
                      </v-layout>
                      <v-btn
                        block
                        small
                        dark
                        height="50px"
                        color="#3CB043"
                        @click="$refs.showFile.click()"
                      >
                        <v-icon style="color: #ffffff"
                          >mdi-note-text-outline</v-icon
                        >
                        <span style="font-family: poppinssemibold"
                          >UPLOAD TEXT BOOK</span
                        >
                      </v-btn>
                      <input
                        v-show="false"
                        id="file"
                        ref="showFile"
                        multiple="multiple"
                        type="file"
                        @change="showFile"
                      />
                    </v-col>
                    <v-col cols="12">
                      <ImageComp
                        :singleImage="image"
                        @stepper="winStepper"
                        :heading="'Upload Banner Image'"
                        :componentType="'courseImage'"
                      />
                    </v-col>
                    <v-col cols="12">
                      <ImageComp
                        :singleImage="frontImage"
                        @stepper="winStepper"
                        :heading="'Upload Image'"
                        :componentType="'frontImage'"
                      />
                    </v-col>
                    <v-col cols="12">
                      <span>Add Description</span>
                    </v-col>
                    <v-col cols="12">
                      <div id="app">
                        <vue-editor v-model="description"></vue-editor>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <span style="font-family: poppinssemibold; color: #000"
                        >Add Overview</span
                      >
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        outlined
                        dense
                        v-model="overview.modeOfEducation"
                        label="Mode of Education"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        outlined
                        dense
                        v-model="overview.security"
                        label="Security"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        outlined
                        dense
                        v-model="overview.language"
                        label="Language"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        outlined
                        dense
                        v-model="overview.certification"
                        label="Certification"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        outlined
                        dense
                        v-model="overview.mode"
                        label="Mode of Examination"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        outlined
                        dense
                        v-model="overview.cpdPoints"
                        label="Continuing Professional Development Points"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <span>Add Learning OutComes</span>
                    </v-col>
                    <v-col cols="12">
                      <div id="app">
                        <vue-editor v-model="learningOutComes"></vue-editor>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <span>Add Who Should Attend</span>
                    </v-col>
                    <v-col cols="12">
                      <div id="app">
                        <vue-editor v-model="whoShouldAttend"></vue-editor>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <span>Add Assesment and Evaluation</span>
                    </v-col>
                    <v-col cols="12">
                      <div id="app">
                        <vue-editor
                          v-model="assesmentAndEvaluation"
                        ></vue-editor>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">
                  Close
                </v-btn>
                <v-btn color="blue darken-1" text @click="add()"> Save </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start v-if="course.length > 0">
      <v-flex xs12 sm6 md4 pa-2 v-for="(item, i) in course" :key="i">
        <v-card>
          <v-layout wrap py-5 px-4>
            <v-flex xs12>
              <v-img :src="mediaURL + item.frontImage" contain
                ><template v-slot:placeholder> <ImageLoader /> </template
              ></v-img>
            </v-flex>
            <v-flex xs12>
              <span style="font-family: poppinssemibold; font-size: 20px">{{
                item.courseName
              }}</span>
            </v-flex>
            <v-flex xs12 pt-2>
              <span style="font-family: poppinsmedium; font-size: 17px"
                >Category : {{ item.category.name }}</span
              >
            </v-flex>
            <v-flex xs12 pt-2 v-if="item.subCategory">
              <span style="font-family: poppinslight; font-size: 15px"
                >SubCategory : {{ item.subCategory.name }}</span
              >
            </v-flex>
            <v-layout wrap>
              <v-flex xs6 sm4 pa-2>
                <v-btn
                  small
                  block
                  class="mr-2"
                  outlined
                  color="blue"
                  :to="'/courseView?id=' + item._id"
                >
                  View
                </v-btn>
              </v-flex>
              <v-flex xs6 sm4 pa-2>
                <v-btn
                  small
                  block
                  class="mr-2"
                  outlined
                  color="green"
                  @click="editSlider(item)"
                >
                  Edit
                </v-btn>
              </v-flex>
              <v-flex xs6 sm4 pa-2>
                <v-dialog v-model="item.delete" max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      class="mr-2"
                      outlined
                      block
                      v-on="on"
                      v-bind="attrs"
                      color="red"
                    >
                      Delete
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title
                      >Are you sure you want to delete this
                      Course?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="item.delete = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItem(item)"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-flex>
            </v-layout>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center v-else>
      <v-flex xs12 py-16 text-center>
        <span style="font-family: poppinsbold; font-size: 25px"
          >No courses available</span
        >
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pt-2>
      <v-flex xs12>
        <div class="text-center pb-5" v-if="pages > 1">
          <v-pagination
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="green"
            circle
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
    <v-dialog v-model="editdialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Course</span>
        </v-card-title>
        <v-card-text>
          <v-layout wrap justify-center>
            <v-col cols="12" sm="6">
              <v-select
                dense
                outlined
                v-model="category"
                class="mt-5"
                label="Category"
                :items="categoryArray"
                item-text="name"
                item-value="_id"
                required
                readonly
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                dense
                outlined
                v-model="subCategory"
                class="mt-5"
                label="Sub Category"
                :items="subCategoryArray"
                item-text="name"
                item-value="_id"
                required
                readonly
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                outlined
                dense
                v-model="editingitem.courseName"
                label="Course Name"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                outlined
                dense
                v-model="editingitem.studyMaterialLink"
                label="Course Material Link"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                outlined
                dense
                v-model="editingitem.courseAmount"
                label="Amount"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                outlined
                dense
                v-model="editingitem.offerPrice"
                label="Offer Price"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-select
                dense
                outlined
                v-model="editingitem.courcesIncluded"
                multiple
                class="mt-0"
                label="Courses included"
                :items="coursesNewArray"
                item-text="courseName"
                item-value="_id"
                required
              >
              </v-select>
            </v-col>
            <v-col cols="12">
              <span style="font-family: poppinssemibold; color: #000"
                >Please Upload PDF</span
              >
              <v-layout justify-center wrap pb-2 v-if="file">
                <v-flex xs12 text-center>
                  <span style="font-family: poppinsregular; color: #8f8f8f">{{
                    file.name
                  }}</span>
                </v-flex>
              </v-layout>
              <v-layout justify-center wrap pb-2 v-else>
                <v-flex xs12 text-center>
                  <span style="font-family: poppinsregular; color: #8f8f8f">{{
                    editingitem.textBook
                  }}</span>
                </v-flex>
              </v-layout>
              <v-btn
                block
                small
                dark
                height="50px"
                color="#3CB043"
                @click="$refs.showFile.click()"
              >
                <v-icon style="color: #ffffff">mdi-note-text-outline</v-icon>
                <span style="font-family: poppinssemibold"
                  >UPLOAD TEXT BOOK</span
                >
              </v-btn>
              <input
                v-show="false"
                id="file"
                ref="showFile"
                multiple="multiple"
                type="file"
                @change="showFile"
              />
              <!-- <v-layout justify-center wrap pt-2>
                    <v-flex xs12 text-center>
                      <span style="font-family: poppinsregular; color: #8f8f8f"
                        >Upload One or Multiple Student Data</span
                      >
                    </v-flex>
                  </v-layout> -->
            </v-col>
            <v-col cols="12">
              <ImageComp
                :singleImage="editingitem.image"
                @stepper="winStepper"
                :heading="'Upload Banner Image'"
                :componentType="'courseImage'"
              />
            </v-col>
            <v-col cols="12">
              <ImageComp
                :singleImage="editingitem.frontImage"
                @stepper="winStepper"
                :heading="'Upload Image'"
                :componentType="'frontImage'"
              />
            </v-col>
            <v-col cols="12">
              <span>Edit Description</span>
            </v-col>
            <v-col cols="12">
              <div id="app">
                <vue-editor v-model="editingitem.description"></vue-editor>
              </div>
            </v-col>
            <v-col cols="12">
              <span style="font-family: poppinssemibold; color: #000"
                >Edit Overview</span
              >
            </v-col>
            <v-col cols="12" sm="6" v-if="editingitem.overView">
              <v-text-field
                outlined
                dense
                v-model="editingitem.overView.modeOfEducation"
                label="Mode of Education"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" v-if="editingitem.overView">
              <v-text-field
                outlined
                dense
                v-model="editingitem.overView.security"
                label="Security"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" v-if="editingitem.overView">
              <v-text-field
                outlined
                dense
                v-model="editingitem.overView.language"
                label="Language"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" v-if="editingitem.overView">
              <v-text-field
                outlined
                dense
                v-model="editingitem.overView.certification"
                label="Certification"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" v-if="editingitem.overView">
              <v-text-field
                outlined
                dense
                v-model="editingitem.overView.mode"
                label="Mode of Examination"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" v-if="editingitem.overView">
              <v-text-field
                outlined
                dense
                v-model="editingitem.overView.cpdPoints"
                label="Continuing Professional Development Points"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <span>Edit Learning OutComes</span>
            </v-col>
            <v-col cols="12">
              <div id="app">
                <vue-editor v-model="editingitem.learningOutComes"></vue-editor>
              </div>
            </v-col>
            <v-col cols="12">
              <span>Edit Who Should Attend</span>
            </v-col>
            <v-col cols="12">
              <div id="app">
                <vue-editor v-model="editingitem.whoShouldAttend"></vue-editor>
              </div>
            </v-col>
            <v-col cols="12">
              <span>Edit Assesment and Evaluation</span>
            </v-col>
            <v-col cols="12">
              <div id="app">
                <vue-editor
                  v-model="editingitem.assesmentAndEvaluation"
                ></vue-editor>
              </div>
            </v-col>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editdialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="edit(editingitem._id)">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import ImageComp from "@/components/Common/singleImages";
import axios from "axios";
export default {
  components: {
    ImageComp,
    VueEditor,
  },
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      dialog: false,
      editdialog: false,
      editingitem: {},

      appLoading: false,
      dialogDelete: false,
      attachments: null,
      attachmentsformData: new FormData(),
      cId: "",
      course: {},
      category: "",
      subCategory: "",
      courceName: "",
      image: "",
      learningOutComes: "",
      whoShouldAttend: "",
      assesmentAndEvaluation: "",
      offerPrice: "",
      amount: "",
      description: "",
      isMultiCource: false,
      studyMaterialLink: "",
      cources: [],
      courseImage: "",
      frontImage: "",
      categoryArray: [],
      subCategoryArray: [],
      categoryArrayFilter: [],
      subCategoryArrayFilter: [],
      categoryFilter: "",
      subCategoryFilter: "",
      coursesNewArray: [],
      courseIds: "",
      filePreview: false,
      showFilePreview: false,
      file: null,
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 9,
      overview: {
        modeOfEducation: "",
        security: "",
        language: "",
        certification: "",
        mode: "",
        cpdPoints: "",
      },
    };
  },
  watch: {
    category() {
      this.getSubcategory();
    },
    currentPage() {
      if (this.categoryFilter == "" && this.subCategoryFilter == "") {
        this.$store.commit("changeCurrentPage", this.currentPage);
        this.getData();
      } else this.getData();
    },
    categoryFilter() {
      this.currentPage = 1;
      this.filterGetSubcategory();
      this.getData();
    },
    subCategoryFilter() {
      this.currentPage = 1;
      this.getData();
    },
  },
  mounted() {
    if (
      this.$store.state.currentPage &&
      this.categoryFilter == "" &&
      this.subCategoryFilter == ""
    ) {
      this.currentPage = this.$store.state.currentPage;
      this.getData();
    } else this.getData();
    // this.getData();
    this.getCategory();
    this.filterGetCategory();
    this.getCourses();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "courseImage") {
        this.courseImage = window_data.selectedFiles;
      }
      if (window_data.type == "frontImage") {
        this.frontImage = window_data.selectedFiles;
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/course/list",
        method: "get",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          limit: this.limit,
          page: this.currentPage,
          category: this.categoryFilter,
          subCategory: this.subCategoryFilter,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.course = response.data.data;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getCategory() {
      this.appLoading = true;
      axios({
        url: "/course/catogory/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.categoryArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getSubcategory() {
      this.appLoading = true;
      axios({
        url: "/course/sub-catogory/list",

        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          categoryId: this.category,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.subCategoryArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    filterGetCategory() {
      this.appLoading = true;
      axios({
        url: "/course/catogory/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.categoryArrayFilter = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    filterGetSubcategory() {
      this.appLoading = true;
      axios({
        url: "/course/sub-catogory/list",

        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          categoryId: this.category,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.subCategoryArrayFilter = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getCourses() {
      this.appLoading = true;
      axios({
        url: "/course/standAlone/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.coursesNewArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    add() {
      this.appLoading = true;
      var user = {};
      user["category"] = this.category;
      user["subCategory"] = this.subCategory;
      user["courceName"] = this.courceName;
      user["amount"] = this.amount;
      user["offerPrice"] = this.offerPrice;
      user["description"] = this.description;
      user["courseIds"] = this.courseIds;
      user["overview"] = this.overview;
      user["learningOutComes"] = this.learningOutComes;
      user["whoShouldAttend"] = this.whoShouldAttend;
      user["assesmentAndEvaluation"] = this.assesmentAndEvaluation;
      user["isMultiCource"] = this.isMultiCource;
      user["cources"] = this.cources;
      user["studyMaterialLink"] = this.studyMaterialLink;

      axios({
        url: "/course/add",
        method: "POST",
        data: user,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            var id = response.data.data._id;
            if (this.courseImage) {
              this.uploadImage(id);
            }
            if (this.frontImage) {
              this.uploadFrontImage(id);
            }
            if (this.file) {
              this.uploadFile(id);
            }
            this.dialog = false;
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.getData();
            this.dialog = false;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editSlider(item) {
      this.editingitem = item;
      this.editdialog = true;
      this.category = this.editingitem.category._id;
      if (this.editingitem.subCategory)
        this.subCategory = this.editingitem.subCategory._id;
    },
    edit() {
      this.appLoading = true;
      var user = {};
      user["category"] = this.category;
      user["subCategory"] = this.editingitem.subCategory;
      user["courceName"] = this.editingitem.courseName;
      user["amount"] = this.editingitem.courseAmount;
      user["offerPrice"] = this.editingitem.offerPrice;
      user["courseIds"] = this.editingitem.courcesIncluded;
      user["overview"] = this.editingitem.overView;
      user["description"] = this.editingitem.description;
      user["learningOutComes"] = this.editingitem.learningOutComes;
      user["whoShouldAttend"] = this.editingitem.whoShouldAttend;
      user["assesmentAndEvaluation"] = this.editingitem.assesmentAndEvaluation;
      user["isMultiCource"] = this.isMultiCource;
      user["cources"] = this.cources;
      user["studyMaterialLink"] = this.editingitem.studyMaterialLink;
      user["id"] = this.editingitem._id;
      axios({
        url: "/course/edit",
        method: "POST",
        data: user,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editdialog = false;
            if (this.courseImage) {
              this.uploadImage(this.editingitem._id);
            }
            if (this.frontImage) {
              this.uploadFrontImage(this.editingitem._id);
            }
            if (this.file) {
              this.uploadFile(this.editingitem._id);
            }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem(r) {
      axios({
        url: "/course/delete",
        method: "GET",
        params: {
          id: r._id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadImage(id) {
      let formData = new FormData();
      formData.append("id", id);
      formData.append("photo", this.courseImage);

      axios({
        method: "POST",
        url: "/course/upload/image/new",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.dialog = false;
            formData = new FormData();
            this.courseImage = "";
            location.reload();
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadFrontImage(id) {
      let formData = new FormData();
      formData.append("id", id);
      formData.append("photo", this.frontImage);

      axios({
        method: "POST",
        url: "/course/upload/front/image/new",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.dialog = false;
            formData = new FormData();
            this.courseImage = "";
            location.reload();
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    showFile(e) {
      this.file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.filePreview = e.target.result;
      };
      if (this.file.size > 3145728) {
        this.msg = "File Should be less than 3MB";
        this.showsnackbar = true;
        return;
      }
      this.showFilePreview = true;
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    },
    uploadFile(id) {
      let formData = new FormData();
      formData.append("document", this.file);
      formData.append("id", id);
      axios({
        method: "POST",
        url: "/course/textBook/upload",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = "Uploaded Successully";
            this.showsnackbar = true;
            this.dialogExcel = false;
            this.file = null;
            this.filePreview = null;
            this.showFilePreview = null;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.msg = "Can't Upload Image.. Please Try Again Later";
          this.showsnackbar = true;
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>